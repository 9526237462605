exports.components = {
  "component---src-page-templates-blog-index-js": () => import("./../../../src/page-templates/blog-index.js" /* webpackChunkName: "component---src-page-templates-blog-index-js" */),
  "component---src-page-templates-blog-show-js": () => import("./../../../src/page-templates/blog-show.js" /* webpackChunkName: "component---src-page-templates-blog-show-js" */),
  "component---src-page-templates-book-index-js": () => import("./../../../src/page-templates/book-index.js" /* webpackChunkName: "component---src-page-templates-book-index-js" */),
  "component---src-page-templates-book-show-js": () => import("./../../../src/page-templates/book-show.js" /* webpackChunkName: "component---src-page-templates-book-show-js" */),
  "component---src-page-templates-static-page-js": () => import("./../../../src/page-templates/static-page.js" /* webpackChunkName: "component---src-page-templates-static-page-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

